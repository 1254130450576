<template>
    <div class="all-page">
        <home-nav class="m-hidden-view"></home-nav>
        <gzh-code-view class="m-hidden-view"></gzh-code-view>

        <div class="m-title">
            <div @click="back" style="margin-right: 5px;width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;">
                <van-icon name="arrow-left" size="20"/>
            </div>
            联系客服
        </div>

        <div class="p-title">
            <div style="height: 70px;padding-left: 30px;display: flex;align-items: center;color: #666666">
                <span>当前位置:</span>
                <span style="margin-left: 10px;">首页</span>
                <span style="margin-left: 10px;">></span>
                <span style="margin-left: 10px;">联系客服</span>
            </div>
        </div>

        <div class="kf-view">
            <div class="common-view">
                <div class="top-view-head">
                    <div class="top-view-name_pre"></div>
                    <div class="top-vew-name">
                        QQ客服
                    </div>
                </div>
                <div class="kf-item-view">
                    <img src="../../assets/qq.png" class="kf-img">
                    <div class="kf-info">
                        <div>QQ:2788188888<span @click="copy('2788188888')" class="copy-button">复制</span></div>
                        <div style="color: #888888;margin-top: 10px;font-size: 14px;">
                            工作时间  9:00 - 22:00
                        </div>
                    </div>
                </div>
            </div>
            <div class="common-view">
                <div class="top-view-head">
                    <div class="top-view-name_pre"></div>
                    <div class="top-vew-name">
                        微信客服
                    </div>
                </div>
                <div class="kf-item-view">
                    <img src="../../assets/wx_b.png" class="kf-img">
                    <div class="kf-info">
                        <div @click="openWeixinKf" style="user-select: none;cursor: pointer;color: #ee3a3a">点击立即咨询</div>
                        <div style="color: #888888;margin-top: 10px;font-size: 14px;">
                            工作时间  9:00 - 22:00
                        </div>
                    </div>
                </div>
            </div>

            <div class="common-view">
                <div class="top-view-head">
                    <div class="top-view-name_pre"></div>
                    <div class="top-vew-name">
                        联系电话
                    </div>
                </div>
                <div class="kf-item-view">
                    <img src="../../assets/cellphone.png" class="kf-img">
                    <div class="kf-info">
                        <div>电话: 4006183736<span @click="copy('4006183736')" class="copy-button">复制</span></div>
                        <div style="color: #888888;margin-top: 10px;font-size: 14px;">
                            工作时间  9:00 - 22:00
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <home-footer v-if="from !== 'android_box'" class="m-footer"></home-footer>
    </div>
</template>

<script>
    import {Toast, Dialog} from 'vant'
    import notice from "../../api/notice";

    import homeNav from "@/components/home/HomeNav";
    import homeFooter from "@/components/home/HomeFooter";
    import gzhCodeView from "@/components/home/GzhCodeView";

    export default {
        name: "Kf",
        components: {
            homeNav,
            homeFooter,
            gzhCodeView,
        },
        data() {
            return {
                isSelfSigned: true,
                from: '',
            }
        },
        mounted() {
            this.from = localStorage.getItem('from');
        },
        methods: {
            back() {
                this.$router.back();
            },
            openWeixinKf() {
                let weixinKfUrl = 'https://work.weixin.qq.com/kfid/kfc298fd74660766c12';
                notice.openUrl(weixinKfUrl)
            },
            copy(v) {
                notice.copy(v)
                this.$toast("复制成功");
            },
        }
    }
</script>

<style scoped>
    .all-page {
        overflow: hidden;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        background-color: #f8f7f5;
    }
    .p-title {
        width: 100%;
        max-width: 900px;
        height: 70px;
        background-color: #ffffff;
        border-radius: 8px;
        margin: 10px auto 0;
        z-index: 999;
        position: relative;
    }
    .kf-item-view {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .kf-view {
        width: 100%;
        max-width: 900px;
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .kf-info {
        color: #333333;
        margin-top: 30px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
    }
    .copy-button {
        color: #ee3a3a;
        margin-left: 10px;
        font-size: 16px;
        display: none;
        user-select: none;
        cursor: pointer;
    }
    .common-view {
        width: 290px;
        height: 320px;
        background-color: #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .top-view-name_pre {
        width: 5px;
        height: 20px;
        background-color: #f55050;
    }
    .top-vew-name {
        text-align: left;
        user-select: none;
        margin-left: 10px;
    }
    .top-view-head {
        height: 80px;
        font-size: 25px;
        display: flex;
        align-items: center;
        padding-left: 30px;
    }
    .m-title {
        display: none;
        font-size: 22px;
        font-weight: bold;
        color: #333333;
        width: 100%;
        height: 80px;
        text-align: left;
        align-items: center;
        padding-left: 20px;
        position: fixed;
        z-index: 999;
        background-color: #f8f7f5;
    }
    .kf-img {
        width: 90px;
        height: 90px;
        margin-top: 10px;
        border-radius: 12px;
    }
    @media screen and (max-width: 900px) {
        .m-hidden-view {
            display: none !important;
        }
        .p-title {
            display: none;
        }
        .m-title {
            display: flex;
        }
        .kf-view {
            flex-direction: column;
            margin-top: 80px;
        }
        .common-view {
            width: 90%;
            margin-top: 10px;
        }
        .common-view {
            height: unset;
            margin-bottom: 10px;
        }
        .copy-button {
            display: unset;
        }
        .kf-info {
            margin-top: 0;
            margin-left: 20px;
            height: 60px;
            justify-content: space-between;
            align-items: flex-start;
            font-size: 16px;
        }
        .kf-item-view {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 25px 20px;
        }
        .kf-img {
            width: 70px;
            height: 70px;
        }
    }
</style>