<template>
    <div class="main-container">
        <div class="center-top-title">
            <h4>{{goodsName}}</h4>
        </div>

        <div style="font-size: 13px;">
            <div v-if="showJxfDiscount" style="font-size: 12px;text-align: left;color: #f37171;padding: 0 15px;">
                开服首日2.5折优惠，剩余时间：
                <van-count-down style="display: inline;color: #f37171;font-size: 12px;"
                    ref="countDown"
                    :time="leaveTime"
                    :auto-start="true"
                    @finish="finish"/>
            </div>
            <div class="goods-item">
                <span>需支付</span>
                <div>
                    <span v-if="showDiscount && radio !== '8'" style="color: #9e9e9e;text-decoration: line-through;margin-right: 5px;">
                        {{ payOriginalPrice }}
                    </span>
                    <span v-if="radio === '8'" style="font-weight: bold;color:#ED9015;">{{payOriginalPrice}}</span>
                    <span v-else style="font-weight: bold;color:#ED9015;">{{orderMoney}}</span>
                </div>

            </div>
            <div class="goods-item">
                <span>优惠 / 折扣</span>
                <span v-if="radio === '8'">无优惠</span>
                <span v-else>{{payDiscount}}</span>
            </div>
        </div>

        <div style="font-size: 12px;color: #888888;text-align: left;padding: 0 15px;margin: 8px 0 8px;">
            请选择支付方式
        </div>
        <div class="pay-class">
            <van-radio-group v-model="radio">
                <div @click="radio = '2'" style="display: flex;align-items: center;justify-content: space-between;background:rgba(255,255,255,.1);padding: 5px 8px;margin-bottom: 1px;cursor: pointer;">
                    <div style="display: flex;">
                        <img :src="zfbPayIcon" style="width: 18px;height: 18px;">
                        <span style="font-size: 12px;margin-left: 10px;">支付宝</span>
                    </div>
                    <van-radio name="2" shape="square" icon-size="13px" checked-color="#e73d50"/>
                </div>

                <div @click="radio = '3'" style="display: flex;align-items: center;justify-content: space-between;background: rgba(255,255,255,.1);padding: 5px 8px;margin-bottom: 1px;cursor: pointer;">
                    <div style="display: flex;">
                        <img :src="wxPayIcon" style="width: 18px;height: 18px;">
                        <span style="font-size: 12px;margin-left: 10px;">微信</span>
                    </div>
                    <van-radio name="3" shape="square" icon-size="13px" checked-color="#e73d50"/>
                </div>

                <div @click="radio = '1'" style="display: flex;align-items: center;justify-content: space-between;background: rgba(255,255,255,.1);padding: 5px 8px;margin-bottom: 1px;cursor: pointer;">
                    <div style="display: flex;">
                        <img :src="logo" style="width: 18px;height: 18px;">
                        <span style="font-size: 12px;margin-left: 10px;">金魂币
                            <span style="color: #888888">余额: {{jhb}}</span>
                        </span>
                    </div>
                    <van-radio name="1" shape="square" icon-size="13px" checked-color="#e73d50"/>
                </div>

                <div @click="radio = '8'" style="display: flex;align-items: center;justify-content: space-between;background: rgba(255,255,255,.1);padding: 5px 8px;margin-bottom: 5px;cursor: pointer;">
                    <div style="display: flex;">
                        <img :src="logo" style="width: 18px;height: 18px;">
                        <span style="font-size: 12px;margin-left: 10px;">绑币
                            <span style="color: #888888">余额: {{bindb}}</span>
                        </span>
                    </div>
                    <van-radio name="8" shape="square" icon-size="13px" checked-color="#e73d50"/>
                </div>

            </van-radio-group>
        </div>

        <div class="box-button">
            <van-button type="info" @click="submitPay" block native-type="submit" size="small" round style="width: 160px;"
                        :loading="loading" loading-text="正在支付"
                        color="#e73d50">
                确认支付
            </van-button>
            <p class="text-button" @click="back">取消支付
            </p>
        </div>
    </div>
</template>

<script>

    import {request, getBalance} from '@/api/request'
    //import crypto from '@/utils/crypto.js';
    import wxPayIcon from "@/assets/pay_wx.png";
    import logo from "@/assets/logo.png";
    import zfbPayIcon from "@/assets/pay_zfb.png";
    import {Toast} from 'vant'

    export default {
        name: 'pay',
        data: function () {
            return {
                wxPayIcon,
                zfbPayIcon,
                logo,
                jhb: '0',
                bindb: '0',
                goodsName: '',
                money: '',
                orderMoney: '',
                uid: '-',
                radio: '',
                loading: false,
                channelId: '',
                gameId: '',
                order: {},
                showDiscount: false,
                preferenceMoney: '',
                payDiscount: '无优惠',
                payOriginalPrice: '',
                from: '',
                leaveTime: 0,
                showJxfDiscount: false,
            }
        },
        mounted() {
            let user = JSON.parse(localStorage.getItem('user'));
            this.uid = user.userId;
            this.channelId = localStorage.getItem("channelId");
            this.gameId = localStorage.getItem("gameId");
            this.from = localStorage.getItem("from");
        },
        methods: {
            finish() {
                this.showJxfDiscount = false;
            },
            initOrder(order) {
                console.log('initOrder...')
                this.goodsName = order.goodsName;
                this.orderMoney = "￥" + (order.money / 100).toFixed(2);
                this.payOriginalPrice = "￥" + (order.money / 100).toFixed(2);
                this.money = order.money;
                this.order = order;

                let options = {
                    url:"/h5/checkUserForJS",
                    data: {
                        channelId: this.channelId,
                        device: "H5",
                        sdkVersion: "1.0.0",
                        gameId: this.order.gameId,
                        cpOrder: this.order.cpOrderId,
                        goodsName: this.goodsName,
                        money: this.money,
                        paymentType: this.handPayType(this.radio),
                        serviceId: this.order.serverId,
                        desc: this.goodsName,
                        roleName: this.order.roleName,
                    }
                };

                request(options).then(res => {
                    if (res.data.success) {
                        if (res.data.data.discount !== "1") {
                            this.showDiscount = true;
                            this.preferenceMoney = "￥" + res.data.data.preferenceMoney + ' / ' + res.data.data.discount;
                        } else {
                            this.showDiscount = false;
                        }
                        this.payDiscount = "￥" + res.data.data.preferenceMoney + ' / ' + res.data.data.discount ;
                        this.orderMoney = "￥" + res.data.data.orderMoney;

                        this.leaveTime =  parseInt(res.data.data.leaveTime) * 1000;
                        if (this.leaveTime !== '0') {//当前有2.5折活动
                            this.showJxfDiscount = true;
                        }

                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            back() {
                this.$parent.$parent.closeJxfPayPage();
            },
            getBalance() {
                let params = {
                    channelId: this.channelId,
                    device: 'H5',
                    gameId: this.gameId,
                };
                getBalance(params).then(res => {
                    if (res.data.success) {
                        this.jhb = res.data.data.balance;
                        this.bindb = res.data.data.bindBalance;
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            submitPay() {
                if (!this.radio) {
                    Toast('请选择支付方式');
                    return;
                }
                this.loading = true;

                let options = {
                    url:"/h5/preOrderForJS",
                    data: {
                        channelId: this.channelId,
                        device: "H5",
                        sdkVersion: "1.0.0",
                        gameId: this.order.gameId,
                        cpOrder: this.order.cpOrderId,
                        goodsName: this.goodsName,
                        money: this.money,
                        paymentType: this.handPayType(this.radio),
                        serviceId: this.order.serverId,
                        desc: this.goodsName,
                        roleName: this.order.roleName,
                        roleId: this.order.roleId,
                        time: this.order.time,
                        sign: this.order.sign
                    }
                };
                request(options).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        if (res.data.data.paymentType !== "1" && res.data.data.paymentType !== "8") {
                            let payUrl = res.data.data.payString;
                            let orderId = res.data.data.orderId;
                            if (this.from.includes('android')) {
                                window.androidJs.openUrl(payUrl);
                            }
                            else {
                                this.$parent.$parent.openPayUrl(payUrl, res.data.data.paymentType, this.orderMoney, this.payOriginalPrice, orderId);
                            }
                        }
                        else {
                            Toast('支付成功');
                        }
                        this.back();
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            handPayType(payType) {
                let isMobile = this.isMobile();
                if (isMobile) {
                    return payType;
                }
                if (payType === '1' || payType === '8') {
                    return payType
                }
                if (payType === '2') {
                    return '4'
                }
                if (payType === '3') {
                    return '5'
                }
            },
            isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
                return flag;
            },
        }
    }
</script>

<style scoped>
    .main-container {
        padding: 10px 15px;
        box-sizing: border-box;
    }
    .goods-item {
        height: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #dddddd;
        padding: 0 15px;
    }

    .center-top-title {
        vertical-align: center;
        height: 2rem;
        line-height: 2rem;
    }

    .center-top-title h4 {
        display: inline-block;
        font-size: 20px;
        margin: 0;
        color: #ED9015;
    }

    .top-label span {
        margin-left: .5rem;
    }

    .pay-class {
        text-align: left;
        padding: 0 16px;
        color: #eeeeee;
        user-select: none;
    }

    .box-button {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 12px 0 0;
    }

    p {
        margin: 0;
    }

    .text-button {
        font-size: .8rem;
        margin-top: 8px;
        color: #888888;
        cursor: pointer;
    }

</style>
