<template>
    <div class="main-container">
        <van-form @submit="onSubmit" ref="submitForm">
            <div class="dialog-title">
                <img @click="back" src="../assets/back.png" style="width: 25px;height: 25px;">
                <div style="margin-left: 5px;">修改密码</div>
            </div>

            <div style="margin: 10px 20px; height: 250px;overflow-y: auto;">
                <van-field label-width="70px" label="原密码" label-class="inline-label" type="password" placeholder="请输入您的原密码"
                           style="margin-top: 10px;" v-model="oldPassword" clearable/>

                <van-field label-width="70px" label="新密码" label-class="inline-label" type="password" placeholder="请输入新密码" style="margin-top: 10px;"
                           v-model="newPassword" clearable/>

                <van-field label-width="70px" label="确认密码" label-class="inline-label" type="password" placeholder="请再次输入新密码"
                           style="margin-top: 10px;" v-model="confirmPassword" clearable/>

                <div class="box-button">
                    <van-button type="info" block native-type="submit" round :loading="loading" style="width: 160px;"  loading-text="" size="small"
                                color="#e73d50">
                        确认修改
                    </van-button>
                </div>
            </div>

        </van-form>

    </div>
</template>

<script>

    import {modifyPasswordByOld} from '@/api/request'
    import crypto from '@/utils/crypto.js';
    import passwordIcon from "@/assets/password.png";
    import backIcon from "@/assets/back.png";
    import {Dialog} from 'vant'
    import {Toast} from 'vant'

    export default {
        name: 'changePassword',
        data() {
            return {
                passwordIcon,
                backIcon,
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
                passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+.]{6,20}$/,
                loading: false,
            };
        },
        methods: {
            getParent() {
                let from = localStorage.getItem("from");
                if (from === 'android_sdk') {
                    return  this.$parent;
                }
                else {
                    return this.$parent.$parent;
                }
            },
            back() {
                this.getParent().hideChangePage();
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }

                this.loading = true;
                let channelId = localStorage.getItem("channelId");
                let gameId = localStorage.getItem("gameId");
                let params = {
                    device: 'H5',
                    oldPassword: crypto.encrypt(this.oldPassword),
                    newPassword: crypto.encrypt(this.newPassword),
                };
                modifyPasswordByOld(params).then(res => {
                    this.loading = false;
                    if (res.data.success) {
                        this.getParent().changePasswordShow = false;
                        this.getParent().mainDialogShow = false;
                        Dialog.alert({
                            message: '密码修改成功，请重新登录!',
                        }).then(() => {
                            this.getParent().logoutCallback();
                        });
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            checkForm() {
                if (!this.oldPassword) {
                    return "请输入原密码";
                }
                if (!this.passwordPattern.test(this.oldPassword)) {
                    return "原密码为6-20位数字字母特殊符号";
                }
                if (!this.newPassword) {
                    return "请输入新密码";
                }
                if (!this.passwordPattern.test(this.newPassword)) {
                    return "新密码为6-20位数字字母特殊符号";
                }
                if (!this.confirmPassword) {
                    return "请再次输入密码";
                }
                if (this.newPassword !== this.confirmPassword) {
                    return "两次密码输入不一致，请重新输入";
                }
                return "ok";
            },
        }
    }
</script>

<style scoped>
    .main-container {
        overflow: hidden;
    }

    .dialog-title {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 10px;
        width: 100%;
        vertical-align: center;
        font-size: 16px;
        color: #ED9015;
    }
    /deep/.inline-label {
        color: #aaaaaa !important;
    }
    .van-cell{
        padding: 8px 14px;
    }
    .van-field{
        background-color: #2f2f2f;
    }
    /deep/.van-field__control {
        color: #ffffff;
    }
    .box-button {
        margin: 30px 0 10px;
        display: flex;
        justify-content: center;
    }
</style>
