<template>
    <div class="center">
        <img style="width: 100px;height: 100px;margin-top: 40px;" src="../../../public/logo.png"/>
        <div class="main">
            <div class="title-tip">
                <span>绑定微信</span>
            </div>

            <div class="menu-view">
                <div class="uni-navigate-item">
                    <van-form ref='bindWxForm'>
                        <span class="ds-label">账号：</span>
                        <van-field label-width="0" label=""  placeholder="请输入账号" class="border-input" v-model="userName" clearable/>
                        <span class="ds-label" style="margin-top: 15px;">密码：</span>
                        <van-field label-width="0" type="password" label=""  placeholder="请输入密码"  v-model="password" clearable/>
                    </van-form>
                </div>
                <div class="line"></div>
                <div class="tips">
                    <span style="font-size: 13px;color:#5777D9;">绑定微信才能收到系统发出的红包哦</span>
                </div>
            </div>
            <div class="confirm-button" @click="submit">立即绑定</div>
        </div>
    </div>
</template>

<script>
    import { Dialog } from 'vant';
    import { accountBindWx } from '@/api/request'
    import crypto from '@/utils/crypto.js';
    export default {
        name: "bindwx",
        data() {
            return {
                // appid: 'wx9df2c487ce99d6a1',//公众号ID-测试的
                appid: 'wx1621bf5786def834',
                code: '',
                userName: '',
                password: ''
            }
        },
        mounted() {
			let code= this.getUrlKey("code");
			console.log('code',code)
            if (!code) {
                this.getCodeApi();
            }
            this.code = code;
        },
        methods: {
            getUrlKey(name){//获取url 参数
				return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
			},
            getCodeApi(){//获取code   
				let urlNow=encodeURIComponent(window.location.href);
				console.log('urlNow',urlNow)
				// let scope='snsapi_userinfo';    //snsapi_userinfo snsapi_base   //静默授权 用户无感知
				let url= 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+this.appid+'&redirect_uri='+urlNow+'&response_type=code&scope=snsapi_userinfo#wechat_redirect';
				window.location.href = url
			},
            submit() {
                if (this.userName === '' || this.password === '') {
                    Dialog.alert({
                        message: '账号和密码不能为空',
                    }).then(() => {

                    });
                    return;
                }
                let params = {
                    userName: this.userName,
                    password: crypto.encrypt(this.password),
                    code: this.code
                };
                accountBindWx(params).then(res => {
                    console.log('res:',res)
                    if (res.data.success) {
                        Dialog.alert({
                            message: '绑定成功',
                        }).then(() => {

                        });
                    } else {
                        Dialog.alert({
                            message: res.data.error.message,
                        }).then(() => {

                        });
                    }
                });
            }
        },


    }
</script>

<style scoped>
    .center {
        display: flex;
        flex: 1;
        flex-direction: column;
        background-color: #F5F6F8;
        height: 100vh;
        box-sizing: border-box;
        align-items: center;
    }
    .title-tip{
        width: 100%;
        text-align: center;
        font-size: 20px;
        margin-top: 10px;
        font-weight: 700;
    }
    .main{
        width: 90%;
        background-color: #FFFFFF;
        margin-top: 50px;
        box-sizing: border-box;
        padding: 10px 15px;
    }
    .line {
        height: 1px;
        background-color: #eceaea;
        margin: 10px 5px;
    }
    .menu-view {
        margin-top: 20px;
        border-radius: 5px;
        padding: 10px 15px;
        background-color: #FFFFFF;
        text-align: left;
    }
    .confirm-button{
        background-color: #5A6EFC;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #FFFFFF;
        font-size: 18px;
        border-radius: 5px;
        width: 200px;
        margin: 20px auto 10px;
    }
    .tips{
        margin: 20px 0;
        padding: 15px 5px;
        background-color: #F0F3FE;
        display: flex;
        align-items: center;
    }
    .common-input {
        flex: 1;
        height: 30px;
        padding: 0 8px;
        font-size: 15px;
        width: 100%;
    }
</style>