<template>

    <div>
        <!-- 登录页面 -->
        <div v-if="step === 1">
            <loginPage ref="loginPage"></loginPage>
        </div>

        <div v-if="step === 2" class="back-div">
            <div class="main-page" ref="mainPage">
                <!--<van-button @click="testPay">支付</van-button>-->
                <div class="dialog-mask" v-show="diyDialogShow">
                    <div class="diy-dialog">
                        <div style="text-align: center;height: 30px;line-height: 30px;">温馨提示</div>
                        <div style="padding: 3px 15px;text-align: left;box-sizing: border-box;margin-top: 10px;">
                            即将打开支付页面，请点击"立即支付"按钮完成支付
                        </div>
                        <div class="diy-dialog-buttons">
                            <div @click="closeDiyDialog()"
                                 style="flex: 1;text-align: center;height: 45px;line-height: 45px;">
                                <a style="color: #888888">取消</a>
                            </div>
                            <div style="flex: 1;text-align: center;height: 45px;line-height: 45px;border-left: 1px solid #f0f0f0;">
                                <a target="_blank" :href="payUrl" @click="closeDiyDialog()"
                                   style="color:#e73d50;">立即支付</a>
                            </div>
                        </div>

                    </div>
                </div>

                <iframe v-show="true" allowfullscreen ref="gameIframe" :src="gameUrl" frameborder="0" scrolling="no"
                        width="100%" height="100%">
                </iframe>

                <van-notice-bar  v-if="showNotice" delay="0"  :style="noticeStyle" style="color: #eca007;pointer-events: none;position: absolute;top: 100px;left: 50%; transform: translate(-50%, -50%); font-size: 14px;width: 100%;max-width: 450px; height: 35px;background-color: rgba(0,0,0,.4)"
                                 speed="30"  :text="noticeMessage" @replay="hideNotice"/>

                <van-dialog :style="dialogStyle" v-model="payShow" class="pay-dialog" :showConfirmButton="false">
                    <pay ref="pay"></pay>
                </van-dialog>

                <van-dialog :style="dialogStyle" v-model="payJxfShow" class="pay-dialog" :showConfirmButton="false">
                    <pay-jxf ref="payJxf"></pay-jxf>
                </van-dialog>


                <van-dialog class="pay-code-dialog" v-model="payCodeShow" v-if="payCodeShow" :showConfirmButton="false">
                    <pay-code ref="payCode"></pay-code>
                </van-dialog>

                <van-popup class="pay-code-dialog" v-model="hasRedShow" v-if="hasRedShow" position="center" :style="{ width: '100%'}" style="background: transparent;">
                    <img @click="openFuliPageFromRed" src="../../assets/has-red-image.png" style="width: 80%;">
                    <img @click="hasRedShow = false" src="../../assets/close-round.png" style="width: 25px;height: 25px;position: absolute;top: 5px;right: 15px;opacity: .6">
                </van-popup>

                <van-dialog :style="dialogStyle" class="main-dialog" v-model="videoPageDialogShow"
                            :showConfirmButton="false">
                    <videoPage ref="videoPage" v-if="videoPageShow"></videoPage>
                </van-dialog>

                <van-dialog :style="dialogStyle" class="main-dialog" v-model="mainDialogShow" v-if="mainDialogShow"
                            :showConfirmButton="false">
<!--                    <dialog-main-menu ref="mainDialog" v-if="mainShow"></dialog-main-menu>-->
<!--                    <kf v-if="kfShow"></kf>-->
<!--                    <gamespeed ref="gameSpeedDialog" v-if="jsPageShow"></gamespeed>-->
                    <real-name ref="realNameDialog" v-if="realNameShow"></real-name>
<!--                    <unbind-tel v-if="unBingTelShow"></unbind-tel>-->
                    <bind-tel ref="bindTelDialog" v-if="bingTelShow"></bind-tel>
<!--                    <change-password v-if="changePasswordShow"></change-password>-->
<!--                    <weixin v-if="bindWeixinShow"></weixin>-->
<!--                    <recharge v-if="rechargeShow"></recharge>-->
<!--                    <gift v-if="giftShow"></gift>-->
<!--                    <gift-record v-if="giftRecordShow"></gift-record>-->

                    <iframe v-if="mainShow" :src="iframeUrl" frameborder="0" scrolling="no" style="width: 100%;height: 340px;"/>
                </van-dialog>

                <van-popup v-model="iframeDialogShow" class="iframe-dialog" position="center" :style="{ width: '100%', height: '100%' }" >
                    <div @click="iframeDialogShow = false" style="width: 35px;height: 35px;position: absolute;top: 10px;left: 10px;
                            background-color: rgba(255,255,255,.2);text-align: center;padding-top: 2px; box-sizing: border-box; border-radius: 4px;">
                        <img src="../../assets/back.png" style="width: 30px;">
                    </div>
                    <iframe v-if="iframeDialogShow" allowfullscreen ref="drawIframe" :src="drawUrl" frameborder="0" scrolling="no"
                             width="100%" height="100%">
                    </iframe>
                </van-popup>

                <div v-if="isShow" class="xuanfu" id="moveDiv"
                     @mousedown="down($event)"
                     @touchstart="down($event)"
                     @mousemove="move($event)"
                     @touchmove="move($event)"
                     @mouseover="end($event)"
                     @mouseleave="move($event)"
                     @mouseup="end($event)"
                     @touchend="end($event)"
                     @click="showMainWindow">
                      
                    <van-image  :src="mainRoundImg" width="50px" height="50px"></van-image>
                    <!--<van-image class="has-red-icon" v-else :src="hasRedIcon" width="50px" height="50px"></van-image>-->
                     
                </div>

                <div v-show="false">
                    <van-count-down
                            ref="countDown"
                            :time="time"
                            :auto-start="false"
                            format="ss"
                            @finish="finish"
                    />
                </div>
            </div>
            <van-overlay :show="openPageShow" @click="openPageShow = false">
                <div class="right-panel">
                    <van-icon @click="closeOutsidePage" style="font-size: 25px;" name="arrow-down"/>
                    <iframe ref="webview_iframe" v-show="true" style="overflow-x:auto;overflow-y:auto"
                            allowfullscreen
                            :src="openPageUrl"
                            frameborder="0"
                            width="100%"
                            height="100%" scrolling="yes">
                    </iframe>
                </div>
            </van-overlay>

        </div>
    </div>

</template>

<script>

    import {logout, roleSync, request} from '@/api/request'

    import changePassword from "@/components/changepassword.vue";
    import bindTel from "@/components/bindtel.vue";
    import unbindTel from "@/components/unbindtel.vue";
    import realName from "@/components/realname.vue";
    import pay from "@/components/pay.vue";
    import payJxf from "@/components/pay-jxf";
    import DialogMainMenu from "@/components/DialogMainMenu.vue";
    import kf from "@/components/kf.vue";
    import gamespeed from "@/components/gamespeed.vue";
    import weixin from "@/components/Weixin.vue";
    import videoPage from "@/components/video.vue";
    import payCode from "@/components/pay-code.vue";
    import loginPage from "@/components/login.vue";
    import mainRoundImg from "@/assets/logo-round.png";
    import hasRedIcon from "@/assets/has-red.png";
    import {Dialog, Toast} from 'vant';
    import timerhook from '@/utils/timerhook';
    import recharge from "@/components/Recharge.vue";
    import gift from "@/components/Gift.vue";
    import giftRecord from "@/components/GiftRecord";

    export default {
        name: 'mainPage',
        components: {
            recharge,
            'change-password': changePassword,
            'bind-tel': bindTel,
            'unbind-tel': unbindTel,
            'real-name': realName,
            'pay': pay,
            'payJxf': payJxf,
            'dialog-main-menu': DialogMainMenu,
            'kf': kf,
            'videoPage': videoPage,
            'pay-code': payCode,
            'weixin': weixin,
            'gift': gift,
            'gift-record': giftRecord,
            loginPage,
            gamespeed
        },
        data() {
            return {
                step: 0,
                mainRoundImg,
                hasRedIcon,
                payShow: false,
                payJxfShow: false,
                mainDialogShow: false,

                mainShow: false,
                changePasswordShow: false,
                bingTelShow: false,
                unBingTelShow: false,
                realNameShow: false,
                kfShow: false,
                openPageShow: false,
                videoPageShow: false,
                videoPageDialogShow: false,
                payDialogShow: false,
                payCodeShow: false,
                iframeDialogShow: false,
                bindWeixinShow: false,
                hasRedShow: false,
                diyDialogShow: false,
                rechargeShow: false,
                giftShow: false,
                giftRecordShow: false,

                flags: false,
                position: {x: 0, y: 0},
                nx: '', ny: '', dx: '', dy: '', xPum: '', yPum: '',
                isShow: false,

                channelId: '',
                gameId: '',

                buttonHidden: true,
                halfOffset: 0,
                time: 10000,
                firstClick: true,
                gameUrl: '',
                tempGameUrl: '',
                payUrl: '',
                screenModel: 1,//当前屏幕方向 1-竖屏 2-横屏
                screenType: 0,//游戏类型 1-竖屏 2-横屏
                dialogStyle: {},
                openPageUrl: '',//外部链接地址

                path: location.protocol + '//' + location.host + '/#' + this.$route.path, //当前页面url
                speedRate: 1,
                jsPageShow: false,
                drawUrl: '',
                hasRed: false,
                shoutId: 0,
                shoutMsgList: [],
                showNotice: false,
                noticeStyle: '',
                noticeMessage: '',
                heartBeatGameId: [1, 2, 48],
                skipCheckTel: false,

                iframeUrl: 'https://game.etuw.cn/f/#/sdk/main',
                showJsButton: '0',
            };
        },
        mounted() {
            console.log('页面初始化');
            this.initUrlParams();
            this.transformChannel();
            this.handleLoginStatus();
        },
        methods: {
            initUrlParams() {
                this.from = this.$route.query.from || '';
                localStorage.setItem('from', this.from);
                this.channelId = this.$route.path.split('/')[2];
                this.gameId = this.$route.path.split('/')[3];

                //处理同一个浏览器  多个标签打开不同的游戏  之前存的token属于不同的游戏，需要清除 重新登录
                /*let localGameId = localStorage.getItem('gameId');
                if (!!localGameId && localGameId !== this.gameId) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                }*/

                localStorage.setItem("channelId", this.channelId);
                localStorage.setItem("gameId", this.gameId);

            },
            transformChannel() {
                let thirdChannel = this.$route.query.thirdChannel || '';
                if (!thirdChannel) {
                    return;
                }
                let options = {
                    url:"/app/transformChannel?channelConfig=" + thirdChannel,
                    method: 'get',
                };
                request(options).then(res => {
                    if (res.data.success) {
                        let result = res.data.data;
                        console.log('渠道号转化成功，第三方渠道号: ' + thirdChannel + ", 转换结果: " + result);
                        localStorage.setItem("channelId", result);
                    }
                    else {
                        console.log('渠道号转化失败');
                    }
                });
            },
            handleLoginStatus () {
                this.step = 1;
                this.$nextTick(function () {
                    this.$refs.loginPage.from = this.from;
                })
            },
            /*handleLoginStatus() {
                let userInfo = localStorage.getItem('user');
                let token = localStorage.getItem('token');
                console.log('userInfo', !userInfo);
                console.log('token', !token);
                if (!!userInfo && !!token) {
                    let options = {
                        url:"/h5/refreshGame",
                        data: {
                            gameId: this.gameId
                        }
                    };
                    request(options).then(res => {
                        if (res.data.success) {
                            let gameUrl = res.data.data;
                            localStorage.setItem('loginUrl', gameUrl);
                            this.skipCheckTel = true;
                            this.enterGame();
                        }
                        else {
                            this.step = 1;
                            this.$nextTick(function () {
                                this.$refs.loginPage.from = this.from;
                            })
                        }
                    });
                }
                else {
                    this.step = 1;
                    this.$nextTick(function () {
                        this.$refs.loginPage.from = this.from;
                    })
                }
            },*/
            enterGame() {
                this.step = 2;
                let user = JSON.parse(localStorage.getItem("user"));
                if (!!user) {
                    this.screenType = user.screenType;
                }

                //开启心跳的游戏id
                if(this.heartBeatGameId.includes(parseInt(this.gameId))) {
                    this.heartBeat();
                    this.showShoutMsg();
                }

                this.tempGameUrl = localStorage.getItem('loginUrl');
                localStorage.removeItem('loginUrl');
                this.isShow = true;
                this.$nextTick(function () {
                    //this.start();
                    this.initListener();
                    if (this.screenType === 1) {
                        this.$refs.mainPage.style.maxWidth = "600px";
                    }
                })

                if (this.skipCheckTel) {
                    this.isRealName();
                }
                else {
                    this.isBindPhone();
                }

                if (this.from.includes('speedOn')) {
                    this.showJsButton = "1";
                }
                this.iframeUrl = user.webviewPageUrl + "?gameId=" + this.gameId + "&token=" + localStorage.getItem("token") + "&from=" + this.from + "&showJsButton=" + this.showJsButton;
                // this.iframeUrl = "http://192.168.1.59:8081/f/#/sdk/main?gameId=" + this.gameId + "&token=" + localStorage.getItem("token") + "&from=h5&showJsButton=" + this.showJsButton;
            },
            heartBeat() {
                let options = {
                    url:"/h5/heartBeat",
                    data: {
                        shoutId: this.shoutId,
                        gameId: this.gameId
                    }
                };
                request(options).then(res => {
                    let c = false;
                    if (res.data.success) {
                        let has = res.data.data.redStatus;
                        this.hasRed = has === '1';

                        if (this.hasRed) {//有红包雨活动
                            let redId = res.data.data.redId;
                            let saveRedId = localStorage.getItem('showRedId');
                            if (redId !== saveRedId) {//判断这个红包雨活动是否展示过，已展示的话就不再展示
                                this.hasRedShow = true;
                                localStorage.setItem('showRedId', redId);
                            }
                        }

                        let msgList = res.data.data.shoutMsgList;
                        for (let i = 0; i < msgList.length; i++) {
                            let msg = msgList[i];
                            this.shoutId = msg.id;
                            this.shoutMsgList.push(msg);
                        }
                        c = true;
                    }
                    else {
                        console.log(res.data.error.message);
                        if (res.data.error.code !== '911') {
                            c = true;
                        }
                    }

                    if (c) {
                        setTimeout(() => {
                            this.heartBeat();
                        }, 15000);
                    }

                });
            },
            hideNotice() {
                setTimeout(() => {
                    this.showNotice = false;
                    this.showShoutMsg();
                }, 1000)
            },
            showShoutMsg() {
                if (this.shoutMsgList.length > 0) {
                    let msg = this.shoutMsgList[0];
                    if (this.shoutMsgList.length > 1) {
                        this.shoutMsgList = this.shoutMsgList.slice(1);
                    }
                    else {
                        this.shoutMsgList = [];
                    }

                    let msgResult = '';
                    if (msg.msgType === 1) {
                        this.noticeStyle = '';
                        msgResult = `[${msg.serverName}] ${msg.roleName} ：${msg.msg}`;
                    }
                    else if (msg.msgType === 2) {
                        this.noticeStyle = 'font-weight: bold; color: #E74C3C; ';
                        msgResult = `官方通知： ${msg.msg}`;
                    }
                    else {
                        msgResult = `${msg.msg}`;
                    }

                    this.noticeMessage = '                                                                                                    ' + msgResult;
                    this.noticeShowTime = new Date().getTime();
                    this.showNotice = true;
                    console.log('开始播放时间', this.noticeShowTime)
                }
                else {
                    setTimeout(() => {
                        this.showShoutMsg();
                    }, 3000);
                }
            },
            setSpeed(n) {
                console.log(n)
                this.speedRate = n;
                timerhook.setSpeed(n);
                //Toast('游戏当前加速倍率：' + n);
            },
            isBindPhone() {
                let user = JSON.parse(localStorage.getItem("user"));
                if (!user.phone) {
                    this.mainDialogShow = true;
                    this.bingTelShow = true;
                    this.$nextTick(function () {
                        this.$refs.bindTelDialog.backShow = false;
                        this.$refs.bindTelDialog.missShow = true;
                    })
                } else {
                    this.isRealName();
                }
            },
            missBindTel() {
                this.mainDialogShow = false;
                this.bingTelShow = false;
                this.isRealName();
            },
            isRealName() {
                let user = JSON.parse(localStorage.getItem("user"));
                if (user.isUserCheck !== '1') {
                    this.mainDialogShow = true;
                    this.realNameShow = true;
                    this.$nextTick(function () {
                        this.$refs.realNameDialog.inputEnable = true;
                        this.$refs.realNameDialog.missShow = user.forcePersonCheck !== '1';
                    })
                } else {
                    this.gameUrl = this.tempGameUrl;
                    //this.gameUrl = 'https://game.etuw.cn/#/test';
                }
            },
            missRealName() {
                this.mainDialogShow = false;
                this.realNameShow = false;
                this.gameUrl = this.tempGameUrl;
            },
            openFuliPageFromRed() {
                this.hasRedShow = false;
                this.openFuliPage();
            },
            openFuliPage() {
                this.drawUrl = 'https://game.etuw.cn/w';
                this.iframeDialogShow = true;
            },
            openWheelPage() {
                let token = localStorage.getItem('token');
                let openId = 'oTi-P6mUF6bZbDxFcETXrcI5vAFw';
                this.drawUrl = `https://recharge.etuw.cn/#/award?token=${token}&gameId=${this.gameId}&openId=${openId}`;
                this.iframeDialogShow = true;
            },
            openRedPackPage() {
                let token = localStorage.getItem('token');
                let openId = 'oTi-P6mUF6bZbDxFcETXrcI5vAFw';
                this.drawUrl = `https://recharge.etuw.cn/#/redPackageYu?token=${token}&gameId=${this.gameId}&openId=${openId}`;
                this.iframeDialogShow = true;
            },
            initListener() {
                window.addEventListener("message", this.listener);
            },
            listener(event) {
                console.dir(event);
                let me = this;
                if (event.data.operation === 'pay') {
                    console.log("触发支付");
                    let order = event.data.param;
                    me.openPayPage(order);
                } else if (event.data.operation === 'uploadGameRole') {
                    console.log("触发角色上报");
                    let param = event.data.param;
                    me.uploadRole(param);
                } else if (event.data.operation === 'repeatLogin') {
                    console.log("触发登陆被顶");
                    me.logout();
                } else if(event.data.operation === 'closeMenu') {
                    this.closeMainWindow();
                } else if(event.data.operation === 'logout'){
                    this.logout();
                } else if(event.data.operation === "startIntent") {
                    Toast(event.data.param.message);
                } else if (event.data.operation === 'showIOSPay') {
                    this.closeMainWindow();
                    this.payUrl = event.data.url;
                    this.diyDialogShow = true;
                } else if(event.data.operation === 'gameSpeed') {
                    this.setSpeed(event.data.param.speed);
                } else if(event.data.operation === 'openFullDialog') {
                    this.openFuliPageFromRed();
                }
            },
            uploadRole(roleParam) {
                let params = {
                    channelId: this.channelId,
                    device: "H5",
                    gameId: roleParam.gameId,
                    remark: roleParam.remark,
                    roleId: roleParam.roleId,
                    roleLevel: roleParam.roleLevel,
                    vipLevel: roleParam.vipLevel,
                    roleName: roleParam.roleName,
                    serviceId: roleParam.serverId,
                    serviceName: roleParam.serverName,
                    userId: roleParam.userId,
                }
                roleSync(params).then(res => {
                    if (res.data.success) {
                        this.$refs.gameIframe.contentWindow.postMessage({operation: "onRole", param: 'success'}, "*");
                    } else {
                        console.log(res.data.error.message);
                    }
                });
            },
            start() {
                this.$refs.countDown.reset();
                this.$refs.countDown.start();
            },
            reset() {
                this.$refs.countDown.reset();
            },
            finish() {
                this.unActiveButton();
            },
            //实现移动端拖拽
            down(event) {
                this.flags = true;
                let touch;
                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }
                this.position.x = touch.clientX;
                this.position.y = touch.clientY;
                this.dx = moveDiv.offsetLeft;
                this.dy = moveDiv.offsetTop;
            },
            move(event) {
                if (this.flags) {
                    if (this.buttonHidden) {
                        this.activeButton();
                    }
                    let touch;
                    if (event.touches) {
                        touch = event.touches[0];
                    } else {
                        touch = event;
                    }
                    this.nx = touch.clientX - this.position.x;
                    this.ny = touch.clientY - this.position.y;
                    this.xPum = this.dx + this.nx;
                    this.yPum = this.dy + this.ny;
                    moveDiv.style.left = this.xPum + "px";
                    moveDiv.style.top = this.yPum + "px";
                    //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
                    document.addEventListener("touchmove", function () {
                        event.preventDefault();
                    }, false);
                }
            },
            end(event) {

                this.flags = false;
                if (!this.xPum) {
                    return;
                }

                let {
                    clientHeight: windowHeight,
                    clientWidth: windowWidth
                } = document.documentElement;
                // 计算后坐标  设置 按钮位置
                //this.halfOffset = -moveDiv.clientWidth / 2;
                if (this.yPum > 50 && this.yPum < (windowHeight - 150)) { //  不在顶部 且 不在底部
                    if (this.xPum <= (windowWidth / 2)) {  //  left 小于等于屏幕一半
                        moveDiv.style.left = 0;
                        moveDiv.style.right = 'auto';
                        //this.hiddenOnLeft();
                        this.start();
                    } else { //  left 大于屏幕一半
                        moveDiv.style.left = 'auto';
                        moveDiv.style.right = 0;
                        this.start();
                    }
                } else {
                    if (this.yPum <= 50) {  //  在顶部
                        moveDiv.style.top = 0;
                        moveDiv.style.bottom = 'auto';
                        this.start();
                    } else if (this.yPum >= (windowHeight - 150)) {
                        moveDiv.style.bottom = 0;
                        moveDiv.style.top = 'auto';
                        this.start();
                    }
                    if (this.xPum < 0) {
                        moveDiv.style.left = 0;
                        this.start();
                    } else if (this.xPum > (windowWidth - moveDiv.clientWidth)) {
                        moveDiv.style.right = 0;
                        moveDiv.style.left = 'auto';
                        this.start();
                    }
                }
            },
            activeButton() {
                //moveDiv.style.opacity = 1;
                this.buttonHidden = false;
                if (!moveDiv.style.left) {
                    moveDiv.style.left = "0px"
                }

                if (moveDiv.style.left === '-25px') {
                    moveDiv.style.left = "0px"
                } else if (moveDiv.style.right === '-25px') {
                    moveDiv.style.right = "0px"
                }
                /*moveDiv.style.transform = `translate3d(${this.halfOffset+'px'}, 0, 0)`;
                moveDiv.style.transition = 'transform 1s linear 0s';*/
            },
            unActiveButton() {
                //moveDiv.style.opacity = 0.2;
                this.buttonHidden = true;
                if (moveDiv.style.left === '0px') {
                    moveDiv.style.left = "-25px"
                } else if (moveDiv.style.right === '0px') {
                    moveDiv.style.right = "-25px"
                }

                /*moveDiv.style.transform = `translate3d(${this.halfOffset+'px'}, 0, 0)`;
                moveDiv.style.transition = 'transform 1s linear 0s';*/
            },
            showMainWindow() {
                if (this.buttonHidden) {
                    this.activeButton();
                    this.start();
                    return;
                }
                /*if (this.firstClick) {
                  this.firstClick = false;
                  return;
                }*/
                this.closeAll();
                this.jsPageShow = false;
                this.mainDialogShow = true;
                this.mainShow = true;
                this.$nextTick(function () {
                    //this.$refs.mainDialog.getBalance();
                })
            },
            closeMainWindow() {
                this.mainDialogShow = false;
                this.activeButton();
                this.start();
            },
            hideMainPage() {
                this.mainShow = false;
            },
            showMainPage() {
                this.mainShow = true;
            },
            showBindWeixin() {
                this.hideMainPage();
                this.bindWeixinShow = true;
            },
            openRechargePage() {
              this.hideMainPage();
              this.rechargeShow = true;
            },
            closeRechargePage() {
                this.rechargeShow = false;
                this.showMainPage();
            },
            closeRechargePageForPay() {
                this.rechargeShow = false;
                this.showMainPage();
                this.closeMainWindow();
            },
            hideBindWeixinPage() {
                this.bindWeixinShow = false;
                this.showMainPage();
            },
            showKfPage() {
                this.hideMainPage();
                this.kfShow = true;
            },
            openJsPage() {
                this.hideMainPage();
                this.jsPageShow = true;
                this.$nextTick(function () {
                    this.$refs.gameSpeedDialog.speedRate = this.speedRate;
                })
            },
            openOutsidePage(title, src, type) {
                /*this.videoPageDialogShow = true;
                this.videoPageShow = true;
                this.$nextTick(function () {
                  this.$refs.videoPage.show(title, src, type);
                })*/
                //this.$refs.webview_iframe.document.location.reload();
                //window.parent.document.getElementById('iframe');
                this.$refs.webview_iframe.contentWindow.location.reload(true);
                console.log(this.$refs.webview_iframe.contentWindow);
                this.closeMainWindow();
                let user = JSON.parse(localStorage.getItem('user'));
                this.openPageUrl = window.location.origin + user.webviewPageUrl;
                this.openPageShow = true;
            },
            closeOutsidePage() {
                this.openPageShow = false;
            },
            closeLuckyPage() {
                this.videoPageShow = false;
                this.videoPageDialogShow = false;
            },
            closeKfPage() {
                this.kfShow = false;
                this.showMainPage();
            },
            closeJsPage() {
                this.jsPageShow = false;
                this.showMainPage();
            },
            showChangePage() {
                this.hideMainPage();
                this.changePasswordShow = true;
            },
            hideChangePage() {
                this.changePasswordShow = false;
                this.showMainPage();
            },
            openGiftPage() {
                this.hideMainPage();
                this.giftShow = true;
            },
            closeGiftPage() {
                this.giftShow = false;
                this.showMainPage();
            },
            showGiftRecordPage() {
                this.giftShow = false;
                this.giftRecordShow = true;
            },
            hideGiftRecordPage() {
                this.giftRecordShow = false;
                this.giftShow = true;
            },
            showRealNamePageReadOnly() {
                this.hideMainPage();
                this.realNameShow = true;
                this.$nextTick(function () {
                    this.$refs.realNameDialog.inputEnable = false;
                    this.$refs.realNameDialog.initData();
                })
            },
            hideRealNamePage() {
                this.realNameShow = false;
                this.showMainPage();
            },
            showBindPage() {
                this.hideMainPage();
                this.bingTelShow = true;
                /*this.$nextTick(function() {
                  this.$refs.bindTelDialog.backShow = true;
                })*/
            },
            hideBindPage() {
                this.bingTelShow = false;
                this.showMainPage();
            },
            showUnbindPage() {
                this.hideMainPage();
                this.unBingTelShow = true;
            },
            hideUnbindPage() {
                this.unBingTelShow = false;
                this.showMainPage();
            },
            closeAll() {
                this.changePasswordShow = false;
                this.bingTelShow = false;
                this.unBingTelShow = false;
                this.realNameShow = false;
                this.kfShow = false;
            },
            testPay() {
                let order = {
                    goodsName: '测试商品',
                    money: '200',
                    gameId: '101',
                };
                this.openPayPage(order);
            },
            openPayPage(order) {
                if (this.gameId === '48' || this.gameId === '1' || this.gameId === '2') {
                    this.payJxfShow = true;
                    this.$nextTick(function () {
                        this.$refs.payJxf.initOrder(order);
                        this.$refs.payJxf.getBalance();
                    })
                }
                else {
                    this.payShow = true;
                    this.$nextTick(function () {
                        this.$refs.pay.initOrder(order);
                        this.$refs.pay.getBalance();
                    })
                }

            },
            openPayUrl(url, payType, price, oPrice, orderId) {
                this.payShow = false;
                if (this.isMobile()) {
                    this.payUrl = url;
                    let ua = navigator.userAgent.toLowerCase();
                    if (/iphone|ipad|ipod/.test(ua)) {//判断是否ios
                        this.diyDialogShow = true;
                    } else {
                        window.open(this.payUrl, '');
                    }
                } else {
                    this.payCodeShow = true;
                    this.$nextTick(function () {
                        let data = {
                            payCode: url,
                            payType: payType,
                            payPrice: price,
                            payOriginalPrice: oPrice,
                            orderId: orderId,
                        }
                        this.$refs.payCode.init(data);
                    });

                }

            },
            refreshPage() {
                this.gameUrl = '';
                setTimeout(() => {
                    this.gameUrl = this.tempGameUrl;
                }, 1000)

            },
            closeDiyDialog() {
                this.diyDialogShow = false;
            },
            isMobile() {
                return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            },
            closePayPage() {
                this.payShow = false;
            },
            closeJxfPayPage() {
                this.payJxfShow = false;
            },
            logout() {
                logout().then(res => {
                    this.logoutCallback();
                })
            },
            logoutCallback() {
                window.removeEventListener("message", this.listener);
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('loginUrl');
                Toast('已退出登录');
                this.mainDialogShow = false;
                this.mainShow = false;
                this.changePasswordShow = false;
                this.bingTelShow = false;
                this.unBingTelShow = false;
                this.realNameShow = false;
                this.kfShow = false;
                this.openPageShow = false;
                this.videoPageShow = false;
                this.videoPageDialogShow = false;
                this.payDialogShow = false;
                this.payCodeShow = false;
                this.diyDialogShow = false;
                this.step = 1;
                this.gameUrl = null;
                window.location.reload();
            },
        }
    }
</script>

<style scoped>
    .main-dialog {
        background-color: #2f2f2f;
        width: 88%;
        max-width: 300px;
        height: 320px;
        padding-bottom: 5px;
        border: 1px solid #484848;
        /*box-shadow: 2px 2px 10px 3px rgba(255,255,255,.2);*/
    }

    .pay-code-dialog {
        width: 88%;
        padding-bottom: 5px;
        max-width: 300px;
    }

    .pay-dialog {
        background-color: #2f2f2f;
        width: 88%;
        max-width: 300px;
    }

    .main-page {
        position: relative;
        background-color: #ffffff;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        touch-action: none;
        margin: 0 auto;
    }

    .iframe-dialog {
        background-color: #ffffff;
        width: 100vw;
        height: 100vh;
        padding: 0;
        overflow: hidden;
    }

    .back-div {
        background-color: #eceaea;
    }

    .right-panel {
        position: absolute;
        background-color: white;
        width: 100%;
        height: 95%;
        top: 5%;
        right: 0;
    }

    .dialog-mask {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;
    }

    .diy-dialog {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 8px 1px 8px;
        box-sizing: border-box;
        text-align: left;
        font-size: 14px;
        width: 80%;
        max-width: 300px;
        border-radius: 10px;
        margin: 200px auto 0;

    }

    .diy-dialog-buttons {
        padding: 0 15px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        margin-top: 30px;
        border-top: 1px solid #f0f0f0;
    }

    .xuanfu {
        position: fixed;
        touch-action: none;
        height: 50px;
        width: 50px;
        /* 如果碰到滑动问题，1.3 请检查 z-index。z-index需比web大一级*/
        z-index: 999;
        top: 60%;
        left: -25px;
    }
    /deep/.van-notice-bar__content{
        white-space:pre;
    }

    @keyframes changeIconSize {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    .has-red-icon {
        animation: changeIconSize 1s infinite alternate;
    }
</style>
