import axios from './axios'
//注册
export async function register (params = {}) {
  return axios({
    url: `/h5/register`,
    method: 'post',
    data: params
  })
}
//登陆
export async function login (params = {}) {
  return axios({
    url: `/h5/login`,
    method: 'post',
    data: params
  })
}
//退出
export async function logout () {
  return axios({
    url: `/h5/logout`,
    method: 'post'
  })
}
//获取配置
export async function getConfig (params = {}) {
  return axios({
    url: `/h5/getConfig`,
    method: 'post',
    data: params
  })
}
//修改密码
export async function modifyPasswordByOld (params = {}) {
  return axios({
    url: `/h5/modifyPasswordByOld`,
    method: 'post',
    data: params
  })
}
//查询余额
export async function getBalance (params = {}) {
  return axios({
    url: `/h5/getBalance`,
    method: 'post',
    data: params
  })
}
//发送短信
export async function sendSms (params = {}) {
  return axios({
    url: `/h5/sendSms`,
    method: 'post',
    data: params
  })
}
//绑定手机
export async function bindPhone (params = {}) {
  return axios({
    url: `/h5/bindPhone`,
    method: 'post',
    data: params
  })
}
//解绑手机
export async function unBindPhone (params = {}) {
  return axios({
    url: `/h5/unBindPhone`,
    method: 'post',
    data: params
  })
}
//实名认证
export async function personCheck (params = {}) {
  return axios({
    url: `/h5/personCheck`,
    method: 'post',
    data: params
  })
}
//重置密码
export async function modifyPasswordByPhone (params = {}) {
  return axios({
    url: `/h5/modifyPasswordByPhone`,
    method: 'post',
    data: params
  })
}
//上报角色
export async function roleSync (params = {}) {
  return axios({
    url: `/h5/roleSync`,
    method: 'post',
    data: params
  })
}
//支付
export async function preOrder (params = {}) {
  return axios({
    url: `/h5/preOrder`,
    method: 'post',
    data: params
  })
}


export async function getDownParam (params = {}) {
  return axios({
    url: `/open/gameDownLoad?gameId=${params.gameId}&adminId=${params.adminId}`,
    method: 'get'
  })
}
export async function getIOSParam (params = {}) {
  return axios({
    url: `/open/iosDownLoad?udid=${params.udid}&gameId=${params.gameId}`,
    method: 'get'
  })
}
export async function getIOSParamOld (params = {}) {
  return axios({
    url: `/open/iosDownLoadOld?udid=${params.udid}&gameCode=${params.gameCode}`,
    method: 'get'
  })
}

//联运-沃杨 返回游戏地址
export async function sdkGetGameUrl (params = {}) {
  return axios({
    url: `/dispatch/getGameUrl`,
    method: 'post',
    data: params
  })
}

//联运-沃杨 预下单
export async function sdkPreOrder (params = {}) {
  return axios({
    url: `/dispatch/preOrder`,
    method: 'post',
    data: params
  })
}

//联运-沃杨 角色上报
export async function sdkUploadRole (params = {}) {
  return axios({
    url: `/dispatch/roleSync`,
    method: 'post',
    data: params
  })
}

//获取用户配置信息
export async function checkUser (params = {}) {
  return axios({
    url: `/h5/checkUser`,
    method: 'post',
    data: params
  })
}

//联运-大嘴霸 返回游戏地址
export async function dzbGetGameUrl (params = {}) {
  return axios({
    url: `/dispatch/dzb/getGameUrl`,
    method: 'post',
    data: params
  })
}

//联运-大嘴霸 预下单
export async function dzbPreOrder (params = {}) {
  return axios({
    url: `/dispatch/dzb/preOrder`,
    method: 'post',
    data: params
  })
}

//联运-大嘴霸 角色上报
export async function dzbUploadRole (params = {}) {
  return axios({
    url: `/dispatch/dzb/roleSync`,
    method: 'post',
    data: params
  })
}

//联运-猪猪游 返回游戏地址
export async function zzyGetGameUrl (params = {}) {
  return axios({
    url: `/dispatch/zzy/getGameUrl`,
    method: 'post',
    data: params
  })
}

//联运-猪猪游 预下单
export async function zzyPreOrder (params = {}) {
  return axios({
    url: `/dispatch/zzy/preOrder`,
    method: 'post',
    data: params
  })
}

//联运-猪猪游 角色上报
export async function zzyUploadRole (params = {}) {
  return axios({
    url: `/dispatch/zzy/roleSync`,
    method: 'post',
    data: params
  })
}

//联运-会游 返回游戏地址
export async function huiyouGetGameUrl (params = {}) {
  return axios({
    url: `/dispatch/huiyou/getGameUrl`,
    method: 'post',
    data: params
  })
}

//联运-会游 预下单
export async function huiyouPreOrder (params = {}) {
  return axios({
    url: `/dispatch/huiyou/preOrder`,
    method: 'post',
    data: params
  })
}

//联运-会游 角色上报
export async function huiyouUploadRole (params = {}) {
  return axios({
    url: `/dispatch/huiyou/roleSync`,
    method: 'post',
    data: params
  })
}

//福利码兑换
export async function welfareGet (params = {}) {
  return axios({
    url: `/h5/welfareGet`,
    method: 'post',
    data: params
  })
}

//首页查询游戏数据
export async function getGameList (params = {}) {
  return axios({
    url: `/open/game/gameList`,
    method: 'post',
    data: params
  })
}

//玩家自助充值  根据用户名查询游戏列表
export async function getPlayedGameList (params = {}) {
  return axios({
    url: `/open/order/getPlayedGameList`,
    method: 'post',
    data: params
  })
}

//玩家自助充值  充值
export async function qlOrder (params = {}) {
  return axios({
    url: `/open/order/qlOrder`,
    method: 'post',
    data: params
  })
}

//微信公众号支付
export async function jspay (params = {}) {
  return axios({
    url: `/open/pay/wxjspay`,
    method: 'post',
    data: params
  })
}

//查询优惠券列表
export async function queryDiscountList (params = {}) {
  return axios({
    url: `/webview/queryDiscountList`,
    method: 'post',
    data: params
  })
}

//查询红包列表
export async function queryRedList (params = {}) {
  return axios({
    url: `/webview/queryRedList`,
    method: 'post',
    data: params
  })
}

//联运-朋克 返回游戏地址
export async function punkGetGameUrl (params = {}) {
  return axios({
    url: `/dispatch/punk/getGameUrl`,
    method: 'post',
    data: params
  })
}

//联运-朋克 预下单
export async function punkPreOrder (params = {}) {
  return axios({
    url: `/dispatch/punk/preOrder`,
    method: 'post',
    data: params
  })
}

//联运-朋克 角色上报
export async function punkUploadRole (params = {}) {
  return axios({
    url: `/dispatch/punk/roleSync`,
    method: 'post',
    data: params
  })
}

//联运-晗天 返回游戏地址
export async function hantianGetGameUrl (params = {}) {
  return axios({
    url: `/dispatch/hantian/getGameUrl`,
    method: 'post',
    data: params
  })
}

//联运-晗天 预下单
export async function hantianPreOrder (params = {}) {
  return axios({
    url: `/dispatch/hantian/preOrder`,
    method: 'post',
    data: params
  })
}

//联运-晗天 角色上报
export async function hantianUploadRole (params = {}) {
  return axios({
    url: `/dispatch/hantian/roleSync`,
    method: 'post',
    data: params
  })
}

//查询游戏详情
export async function gameDetail (params = {}) {
  return axios({
    url: `/app/gameDetail`,
    method: 'post',
    data: params
  })
}

//查询攻略页面
export async function getContentById (id) {
  return axios({
    url: `/app/getContentById?contentId=${id}`,
    method: 'get'
  })
}

//根据游戏id查询攻略列表
export async function getContentByGameId (params = {}) {
  return axios({
    url: `/content/contentPage`,
    method: 'post',
    data: params
  })
}

//游戏反馈
export async function fankui (params = {}) {
  return axios({
    url: `/content/fankui`,
    method: 'post',
    data: params
  })
}

export async function wxAuth(params = {}){
  return axios({
    url: `/wx/authWx/${params.code}`,
    method: 'post',
    data: params
  })
}

export async function get(url){
  return axios({
    url: url,
    method: 'get'
  })
}

export async function request(options){
  return axios({
    url: options.url,
    method: options.method || 'post',
    data: options.data || {}
  })
}

//账号绑定微信
export async function accountBindWx (params = {}) {
  return axios({
    url: `/h5/accountBindWx`,
    method: 'post',
    data: params
  })
}


