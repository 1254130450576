<template>
    <div class="nav-back" style="height: 80px;background-color: #3e4757;width: 100%;">
        <div class="nav-center">
            <div class="logo-view">
                <img src="../../assets/logo.png" style="width: 50px;" >
                <span style="margin-left: 20px;font-weight: bold;color: #efefef;font-style: italic;font-size: 25px;">E兔玩</span>
            </div>
            <div class="menu-view">
                <div class="menu" :class="index === 1 && 'menu-active'"  @click="toIndexPage">
                    首页
                    <div class="active-line" :class="preIndex > 1 ? 'from-right' : 'from-left'" v-show="index === 1"></div>
                </div>
                <div class="menu" :class="index === 2 && 'menu-active'"  @click="toGameListPage">
                    所有游戏
                    <div class="active-line" :class="preIndex > 2 ? 'from-right' : 'from-left'" v-show="index === 2"></div>
                </div>
                <div class="menu" :class="index === 3 && 'menu-active'" @click="toFuliPage">
                    福利
                    <div class="active-line" :class="preIndex > 3 ? 'from-right' : 'from-left'" v-show="index === 3"></div>
                </div>
                <div class="menu" :class="index === 4 && 'menu-active'" @click="toKfPage">
                    联系客服
                    <div class="active-line" :class="preIndex > 4 ? 'from-right' : 'from-left'" v-show="index === 4"></div>
                </div>
            </div>
            <div class="search">
                <div class="search-box">
                    <img src="../../assets/search.png" @click="searchGame">
                    <span class="split"></span>
                    <input class="input" v-model="game_name" type="text" @keyup.enter="searchGame">
                    <span class="btn" @click="searchGame">搜索</span>
                </div>
            </div>
            <div class="icon-container" @mouseenter="hoverIn" @mouseleave="hoverOut">
                <img src="../../assets/user.png" class="user">
                <div v-if="dialogHover" class="dropdown1">
                    <img src="../../assets/default-user.png" class="user-icon"> 
                    <p class="no-login">您还未登录</p>
                    <van-button @click="toLoginPage" style=" color: blue;">登录</van-button>
                </div>
                <div v-if="dialogGame" class="dropdown2">
                    <p style="margin-top: 10px;  margin-left:10px; text-align: left; font-weight:bold">登录账号：{{userName}}</p>
                    <p style=" margin-left:10px; text-align: left; font-weight:bold ">我的游戏：</p>
                    <div class="m-main-view">
                        <div v-if="gameList.length > 0"  class="game-center" style="width: 100%;margin-top: 20px;display: flex;flex-wrap: wrap;justify-content: space-between;">
                            <div class="game-item-view" v-for="item in gameList" @click="open(item.game_id)">
                                <img :src="item.image_url" style="width: 70px;height: 70px;border-radius: 10px;">
                                <div style="display: flex;height: 70px; margin-left: 15px;flex-direction: column;justify-content: space-between;overflow: hidden">
                                    <span class="game-name-one-line">{{item.game_name}}</span>
                                    <div class="game-tag-view">
                                        <span>余额：{{item.balance}}</span>
                                        <span>绑币余额：{{item.bindBalance}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else style="font-size: 20px;width: 100%;text-align: center;height: 300px;color: #888888;display: flex;align-items: center;justify-content: center">
                            暂无内容~
                        </div>
                    </div>
                    <div>
                        <van-button @click="exit">退出</van-button>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="false">
            <search-game-list ref="child" :onParentClick="hanleParentClick"></search-game-list>
        </div>
    </div>
</template>


<script>
import {request} from '@/api/request'
import SearchGameList from '../../views/home/SearchGameList.vue';

export default {
  components: { SearchGameList },
    name: "HomeNav",
    data() {
        return {
            index: 0,
            preIndex: '',
            game_name: '',
            dialogHover: false,
            dialogGame: false,
            userName: '',
            gameList: []
        }
    },
    mounted() {
        this.initActiveMenuIndex();
        this.preIndex = localStorage.getItem('preIndexMenu')
    },
    methods: {
        hanleParentClick(message) {
            console.log(message)
        },
        queryGame() {
            let options = {
                url:"/web/myGameList",
                data: {
                    params: {
                        
                    }
                }
            };
            request(options).then(res => {
                if (res.data.success) {
                    this.gameList = res.data.data.gameBaseInfoList;
                    this.userName = res.data.data.userName;
                }
                else {
                    Toast(res.data.error.message);
                }
            });
        },
        toLoginPage() {
            this.dialogHover = false;
            this.$router.push('/login');
        },
        toRegisterPage() {
            this.dialogHover = false;
            this.$router.push('/register');
        },
        exit() {
            this.dialogGame = false;
            this.dialogHover = true;
            localStorage.removeItem('token');
        },
        hoverIn() {
            let token = localStorage.getItem("token");
            if (token) {
                this.dialogGame = true;
                this.queryGame();
            } else {
                this.dialogHover = true;
            }
        },
        hoverOut() {
            let token = localStorage.getItem('token');
            if (token) {
                this.dialogGame = false;
            } else {
                this.dialogHover = false;
            }
        },
        initActiveMenuIndex() {
            let path = this.$route.path;
            if (path === '/index') {
                this.index = 1;
            }
            else if (path === '/home/gameList') {
                this.index = 2;
            }
            else if (path === '/home/fuli') {
                this.index = 3;
            }
            else if (path === '/home/kf') {
                this.index = 4;
            }
        },
        toIndexPage() {
            localStorage.setItem('preIndexMenu', this.index)
            this.$router.push('/index');
        },
        toGameListPage() {
            localStorage.setItem('preIndexMenu', this.index)
            this.$router.push('/home/gameList');
        },
        toKfPage() {
            localStorage.setItem('preIndexMenu', this.index)
            this.$router.push('/home/kf');
        },
        toFuliPage() {
            localStorage.setItem('preIndexMenu', this.index)
            this.$router.push('/home/fuli');
        },
        searchGame() {
            if (this.$route.path === '/home/searchGameList') {
                this.$refs.child.hanleParentClick(this.game_name);
                return;
            }

            this.$router.push({path:'/home/searchGameList',query:{gameName:this.game_name}});
        }
    }
}
</script>

<style scoped>
.nav-center {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.logo-view {
    height: 80px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #FFFFFF;
}
.menu-view {
    display: flex;
    margin-left: 30px;
}
.menu {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    user-select: none;
    position: relative;
}
.menu-active {
    color: #f16868;
}
.active-line {
    width: calc(100% - 40px);
    height: 3px;
    background-color: #f16868;
    position: absolute;
    left: 20px;
    bottom: 0;

}

.from-right {
    animation: right_to_left .3s linear;
}

.from-left {
    animation: left_to_right .3s linear;
}

@keyframes left_to_right {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes right_to_left {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.search-view {
    height: 40px;
    width: 220px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 40px;
    padding: 0 5px 0 10px;
}
.search-input {
    background: transparent;
    outline: none;
    border: none;
    height: 30px;
    margin-left: 10px;
    width: 120px;
}
.search-button {
    height: 30px;
    width: 60px;
    background-color: #4a8fff;
    color: #ffffff;
    border-radius: 30px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 900px) {
    .nav-center {
        margin-left: 20px;
    }
}

.search{
    margin-left: 50px;
}

.search-box{
    position: relative;
}

.search-box img{
    cursor: pointer;
    height: 20px;
    left: 12px;
    top: 6px;
    position: absolute;
    align-items: center;
    vertical-align: middle;
}
.split{
    background-color: #e9ebee;
    height: 20px;
    left: 40px;
    top: 6px;
    position: absolute;
    width: 2px;
    unicode-bidi: isolate;
}

.search-box input{
    border: 1px solid #ccc;   
    height: 30px;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    color: #272b37;
    text-indent: 3em;
    font-family: 'Microsoft YaHei';
    font-weight: normal;
    width: 220px;
}

.btn{
    background: linear-gradient(90deg, #1c70ff, #28aafb);
    border-radius: 15px;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    justify-content: center;
    line-height: 1;
    position: absolute;
    right: 1px;
    top: 1px;
    height: 30px;
    width: 50px;
    align-items: center;
}

.icons-container{
    margin-left: 20px;
}
.user{
    width: 25px;
    height: 25px;
    transform: translateY(-200px);
    filter: drop-shadow(#4c7ddd 0 200px);
}

.icon-container {  
    margin-left: 20px;
    position: relative;  
    display: inline-block;
}  

.icon {  
width: 50px; /* 假设图标宽度为50px */  
height: auto;  
cursor: pointer; /* 鼠标悬停时显示指针 */  
}  

.tooltip {  
position: absolute;  
bottom: 100%; /* 提示框在图标下方 */  
left: 50%;  
transform: translateX(-50%); /* 居中提示框 */  
background-color: black;  
color: white;  
padding: 5px 10px;  
border-radius: 5px;  
visibility: hidden; /* 初始不可见 */  
opacity: 0;  
transition: opacity 0.5s, visibility 0.5s;  
z-index: 1; /* 确保提示框在上方 */  
}  

.icon-container:hover .tooltip {  
visibility: visible;  
opacity: 1;  
}

.tool span{
margin-left: 20px
}

.footer-list{
max-width: 1080px;
height: 60px;
margin: auto;
}
.footer-list ul{
display: flex;
justify-content: space-around;
align-items: center;
height: 100%;
text-align: center;
}
.footer-list ul i{
font-size: 20px;
}
.game-item-view {
        width: 430px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff;
        border-radius: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 20px 25px;
        box-sizing: border-box;
        overflow: hidden;
    }

    .game-item-view:hover .game-name-one-line {
        color: #0e7dde;
    }
    .game-item-view:hover  {
        transform: translateY(-3px);
    }
    .game-name-one-line {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        color: #333333
    }
    .game-tag-view {
        display: flex;
        color: #f15858;
        font-size: 12px;
        white-space: nowrap;
    }
    .game-tag-view span {
        margin-right: 10px;
        background-color: rgba(241, 88, 88, 0.1);
        padding: 2px 4px;
        border-radius: 2px;
    }
    .dropdown1{
        position: relative;
        top: 165px;
        right: -50px;
        background-color: white;
        border: 1px solid #ccc;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        padding: 10px;
        z-index: 1000;
        width: 250px;
        height: 250px;
    }
    .dropdown2{
        position: relative;
        top: 190px;
        right: -100px;
        background-color: white;
        border: 1px solid #ccc;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        padding: 10px;
        z-index: 1000;
        width: 300px;
        height: 300px;
        overflow-y: auto;
    }
</style>